import Styles from "./Icon.module.scss"
import React, {ReactNode} from "react";

export enum EIconType {
    CLOSE,
    CLOSE_RED,
    DISCORD,
    EXTERNAL,
    LINKEDIN,
    MEDIUM,
    MORE,
    MENU,
    TELEGRAM,
    TWITTER,
    UP,
    LINKED_LOGO,
}

export enum EIconSize {
    SMALL,
    BIG
}

const Icon = (
    {
        type,
        size,
        children
    }: {
        type: EIconType,
        size: EIconSize,
        children?: ReactNode;
    }) => {

    const className = `${Styles.iconContainer} ${getTypeClass(type)} ${getSizeClass(size)}`;

    return (
        <div className={className}>
            <div className={Styles.icon}/>
            {children}
        </div>
    )
};

export function getTypeClass(type: EIconType) {
    switch (type) {
        case EIconType.CLOSE:
            return Styles.close;
        case EIconType.CLOSE_RED:
            return Styles.closeRed;
        case EIconType.DISCORD:
            return Styles.discord;
        case EIconType.EXTERNAL:
            return Styles.external;
        case EIconType.LINKEDIN:
            return Styles.linkedin;
        case EIconType.MEDIUM:
            return Styles.medium;
        case EIconType.MORE:
            return Styles.more;
        case EIconType.MENU:
            return Styles.menu;
        case EIconType.TELEGRAM:
            return Styles.telegram;
        case EIconType.TWITTER:
            return Styles.twitter;
        case EIconType.UP:
            return Styles.up;
        case EIconType.LINKED_LOGO:
            return Styles.linkedLogo;
    }
}

export function getSizeClass(type: EIconSize) {
    switch (type) {
        case EIconSize.BIG:
            return Styles.big;
        case EIconSize.SMALL:
            return Styles.small;
    }
}

export default Icon