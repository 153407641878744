import React, { ReactNode } from 'react';
import Styles from "./H3.module.scss";

export const H3 = (
	{
		children
	}: {
		children: ReactNode
	}
) => {
	return (
		<h3 className={Styles.h3}>
			{children}
		</h3>
	);
};

export default H3;
