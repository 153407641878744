import React from "react"
import Styles from "./SideBar.module.scss"
import Icon, {EIconSize, EIconType} from "./Ui/Icon";
import H2 from "./Ui/H2";
import {PagePath} from "../helper/PageHelper";

const SideBar = (
    {
        onCloseSideBar
    }: {
        onCloseSideBar: () => void,
    }
) => {
    return (
        <div className={Styles.container}>
            <div className={Styles.background} onClick={onCloseSideBar}/>
            <div className={Styles.content}>
                <div className={Styles.links}>
                    <H2>
                        <a href={PagePath.ABOUT}>
                            About us
                        </a>
                    </H2>
                    <H2>
                        <a href={PagePath.PROJECTS}>
                            Projects
                        </a>
                    </H2>
                    <H2>
                        <a href={PagePath.TEAM}>
                            Team
                        </a>
                    </H2>
                    <H2>
                        <a href={"/#news"} onClick={onCloseSideBar}>
                            News
                        </a>
                    </H2>
                    <H2>
                        <a href={"/#contact"} onClick={onCloseSideBar}>
                            Contact us
                        </a>
                    </H2>
                </div>
                <div className={Styles.social}>
                    <a href={"https://wecandev.medium.com/"} target={"_blank"}>
                        <Icon type={EIconType.MEDIUM} size={EIconSize.BIG}/>
                    </a>
                    <a href={"https://twitter.com/devwecan"} target={"_blank"}>
                        <Icon type={EIconType.TWITTER} size={EIconSize.BIG}/>
                    </a>
                </div>
            </div>

        </div>

    )
}

export default SideBar
