import React, {ReactNode, useState} from "react"
import Styles from "./Layout.module.scss"
import Icon, {EIconSize, EIconType} from "./Ui/Icon";
import H3 from "./Ui/H3";
import SideBar from "./SideBar";
import logo from "../images/wecan_logo.png";
import {L} from "../l10n/L10n";
import classNames from "classnames";
import {copyMessage} from "../helper/getDOM";
import Ps from "./Ui/Ps";

interface IProps {
    children: ReactNode;
    lightThem?: boolean
}

const Layout = ({children, lightThem = true}: IProps) => {
    const [showSideBar, setShowSideBar] = useState(false);

    function scrollToUp() {
        let up_element = document.getElementById("up");
        if (up_element)
            up_element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
    }

    return (
        <div className={classNames(Styles.containerFluid, `theme-${lightThem ? 'dark' : 'light'}`)}>
            <div id={'up'}/>
            <header className={Styles.header}>
                <a href={"/"}>
                    <div className={Styles.logo}>
                        <img src={logo} alt={"logo"}/>
                    </div>
                </a>
                <div className={Styles.nav}>
                    <div className={Styles.icon} onClick={() => setShowSideBar(!showSideBar)}>
                        {showSideBar ?
                            <Icon type={EIconType.CLOSE_RED} size={EIconSize.BIG}/>
                            :
                            <Icon type={EIconType.MENU} size={EIconSize.BIG}/>
                        }
                    </div>
                </div>
            </header>

            {showSideBar &&
                <SideBar onCloseSideBar={() => setShowSideBar(false)}/>
            }
            <div className={Styles.container}>
                {children}
            </div>
            <footer className={Styles.footer}>
                <div className={Styles.content}>
                    <div className={Styles.requestText}>
                        <H3>{L.contact.title}</H3>
                    </div>
                    <div className={Styles.contact} id={"contact"}>
                        <div className={Styles.mailWrap}
                             onClick={() => copyMessage(L.contact.mails.marketing.mail)}>
                            <Ps>{L.contact.mails.marketing.title}</Ps>
                            <H3>{L.contact.mails.marketing.mail}</H3>
                        </div>
                        <div className={Styles.mailWrap}
                             onClick={() => copyMessage(L.contact.mails.development.mail)}>
                            <Ps>{L.contact.mails.development.title}</Ps>
                            <H3>{L.contact.mails.development.mail}</H3>
                        </div>
                    </div>
                    <div className={Styles.socialWrap}>
                        <a href={"/"}>
                            <div className={Styles.logo}>
                                <img src={logo} alt={"logo"}/>
                            </div>
                        </a>
                        <div className={Styles.btnWrap}>
                            <div className={Styles.social}>
                                <a href={"https://wecandev.medium.com/"} target={"_blank"}>
                                    <Icon type={EIconType.MEDIUM} size={EIconSize.BIG}/>
                                </a>
                                <a href={"https://twitter.com/devwecan"} target={"_blank"}>
                                    <Icon type={EIconType.TWITTER} size={EIconSize.BIG}/>
                                </a>
                            </div>
                            <div className={Styles.upBtn} onClick={() => scrollToUp()}>
                                <Icon type={EIconType.UP} size={EIconSize.BIG}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={Styles.copyright}>
                    <Ps>{L.copyright.replace("$year$", new Date().getFullYear().toString())}</Ps>
                </div>
            </footer>
            <div className={Styles.background}/>
        </div>
    )
}

export default Layout
