import React, { ReactNode } from 'react';
import Styles from "./H2.module.scss";

export const H2 = (
	{
		children
	}: {
		children: ReactNode
	}
) => {
	return (
		<h2 className={Styles.h2}>
			{children}
		</h2>
	);
};

export default H2;
