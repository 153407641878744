export const L = {
    banner: {
        banner_text1: 'We create full-stack blockchain dapps and games.',
        banner_text2: 'We maintain the WAX node and develop the ecosystem.',
        banner_text3: 'We build the metaverse, one gamified world at a time.',
    },
    about: {
        tile: "#1 DevTeam in distributed ledger strategy games",
        text_hover: "R-Planet and Prospectors dapps are among the top games developed on the WAX blockchain.",
        tile_2: "We Create Independent Realities",
        text_2_hover: "We are pioneers in developing stand-alone gamified metaverses with autonomous economies, in-game tokens, and NFTs. ",
        tile_3: "Ecosystem Development",
        text_3_hover: "By allowing NFT staking from multiple projects, we can create network effects between different gaming communities and facilitate the growth of the entire game ecosystem.",
        card_about: "WECAN is a game development team from Ukraine."
    },
    team: {
        title: "Leadership team",
        description: "Our blockchain game development team leverages emerging technology to create gaming applications utilizing WAXio software.",
    },
    contact: {
        title: "We are glad to answer all your questions upon request.",
        mails: {
            marketing: {
                title: "Marketing",
                mail: "cmo@wecan.dev"
            },
            development: {
                title: "Development",
                mail: "dev@wecan.dev"
            }
        }
    },
    copyright: "Copyright © $year$ wecan.dev All rights reserved.",
};