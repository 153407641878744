import React, { ReactNode } from 'react';
import Styles from "./Ps.module.scss";

export const Ps = (
	{
		children
	}: {
		children: ReactNode
	}
) => (
	<p className={Styles.p}>
		{children}
	</p>
);

export default Ps;
